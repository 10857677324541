import "./styles/style.scss";
import ReactDOM from "react-dom";
import * as React from "react";
import { FRAGMENT_NAME } from "../constant";
import StaticContentBody from "./components/static-content-body";

if (window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <StaticContentBody {...window[`__${FRAGMENT_NAME}__PROPS` as keyof Window]} />,
    document.getElementById(FRAGMENT_NAME)
  );
}
