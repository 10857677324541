export enum DemeterEventGroup {
  SCREEN = "screen",
}

interface DemeterData {
  event: string;
  // eslint-disable-next-line camelcase
  event_group: DemeterEventGroup;
  screen: string;
  parameters: Record<string, string | undefined | null>;
}

export const sendDemeterEvent = (eventData: DemeterData) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (!window.demeterInstance) {
    console.error("Demeter instance not found");
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  window.demeterInstance.data(eventData).send();
};
