import React, { FC, useEffect } from "react";
import { DemeterEventGroup, sendDemeterEvent } from "../../../../../utils/demeter";

interface StaticContentBodyProps {
  htmlBody: string;
  culture: string;
  path: string;
  platform: "MWEB" | "WEB";
}

const StaticContentBody: FC<StaticContentBodyProps> = ({
  htmlBody,
  path,
  culture,
  platform,
}: StaticContentBodyProps): JSX.Element => {
  if (htmlBody) {
    useEffect(() => {
      sendDemeterEvent({
        event: "view",
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_group: DemeterEventGroup.SCREEN,
        screen: "static_content",
        parameters: {
          action: "seen",
          label: culture,
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_category: path,
          platform: platform,
        },
      });
    }, [htmlBody]);

    return (
      <div
        className="static-content-container"
        data-drroot="static-content"
        dangerouslySetInnerHTML={{ __html: htmlBody }}
      />
    );
  }
  return <div className="static-content-container" data-drroot="static-content" />;
};

export default StaticContentBody;
